import React from "react"
import Image from "../../image"

const Table = ({
  title,
  text,
  images,
  ctaButtonText,
  ctaDataGoal,
  ctaHref,
  subText,
  storybook,
}) => {
  return (
    <section data-theme="white">
      <div className="container container--md margin-top--lg margin-bottom--lg">
        <div className="col col--12 col--sm-12 text--center  margin-bottom--md">
          <h2>{title}</h2>
          {text && <p className="margin-top--sm">{text}</p>}
        </div>
        <div className="col col--12 col--sm-12 margin-bottom--md">
          {storybook ? (
            <img src="https://via.placeholder.com/1024x745" alt="sb pic" />
          ) : (
            <>
              {images &&
                images.map(img => (
                  <Image
                    key={img.alt}
                    alt={img.alt}
                    filename={img.Filename}
                    classname={img.className}
                  />
                ))}
            </>
          )}
        </div>
        <div className="col col--12 col--sm-12 text--center">
          <a
            data-goal={ctaDataGoal}
            href={ctaHref}
            className="btn btn--primary btn--md btn--lgMob"
          >
            {ctaButtonText}
          </a>
        </div>
      </div>
      <div className="container container--lg text--xs opacity--6 margin-top--sm margin-bottom--lg text--center">
        {subText &&
          subText.map(sub => (
            <p key={sub.text} className={sub.className}>
              {sub.text}
            </p>
          ))}
      </div>
    </section>
  )
}
export default Table
